import moment from 'moment';

export function convertSlotsToFilledSchedule(schedule, shows) {
    let missingSlots = []
    let overlappingSlots = []
    let filledSchedule = {}
    const daysArray = new Array(7).fill().map((_, i) => i + 1)
    const hoursArray = new Array(24).fill().map((_, i) => moment({ hour: i }).format('HH:mm'))

    if (schedule !== null && shows.length !== 0) {
        daysArray.forEach(d => {
            missingSlots.push({ day: d, hours: hoursArray });
        })
        hoursArray.forEach(hour => {
            filledSchedule[hour] = {};
            daysArray.forEach(d => {
                filledSchedule[hour][d] = null
            })
        })
        schedule.forEach(slot => {
            const startHour = slot.TimeStart.split(":")[0]
            const endHour = slot.TimeEnd.split(":")[0]
            const sStart = moment(0).utc().hour(parseInt(startHour));
            const sEnd = moment(0).utc().hour(parseInt(endHour));

            const duration = moment(sEnd).subtract(sStart).unix() / 60 / 60
            for (let i = 0; i < duration; i++) {
                let tempTime = moment(slot.TimeStart, "HH:mm").add({ hour: i }).format("HH:mm")
                if (filledSchedule[tempTime][slot.Day] !== null) {
                    if (overlappingSlots.filter(s => s.ScheduleID === slot.ScheduleID).length !== 0) continue;
                    overlappingSlots.push({ ...slot, overlapWith: filledSchedule[tempTime][slot.Day].id })
                    continue
                }
                const missingDayIndex = missingSlots.findIndex(d => d.day === parseInt(slot.Day));
                missingSlots[missingDayIndex].hours = missingSlots[missingDayIndex].hours.filter(h => h !== tempTime)
                filledSchedule[tempTime][slot.Day] = { id: slot.ScheduleID, ShowID: slot.ShowID, duration: duration - i }
            }
        })
        // var spanningDay = {1:false,2:false,3:false,4:false,5:false,6:false,7:false}
    }

    return filledSchedule;
}

export function convertFilledScheduleToOneDaySlots(schedule, day) {
    if (schedule === null) return []
    const daySlots = [];
    let skipHours = 0;
    Object.keys(schedule).map(slot => {
        if (skipHours > 0) {
            skipHours--;
            return;
        }
        if (schedule[slot][day - 1] !== null) {
            daySlots.push({
                id: schedule[slot][day - 1].id,
                ShowID: schedule[slot][day - 1].ShowID,
                timeStart: slot,
                timeEnd: moment(0).hour(parseInt(slot.split(':')[0]) + schedule[slot][day - 1].duration).format('HH:mm')
            })
            skipHours = schedule[slot][day - 1].duration - 1;
        }
    })
    return daySlots
}

export function getCurrentDay() {
    const currentMomentDay = moment().day();
    if (currentMomentDay === 0) return currentMomentDay + 8
    else return currentMomentDay + 1 // converted from MomentJS 0-6 Sunday-Saturday range to Nerve 2-8 Range: Monday-Sunday
}

export function getDayString(day) {
    return moment().day(day - 1).format('dddd')  // converted from MomentJS 0-6 range to Nerve 2-8 Range: Monday-Sunday
}

export function getCurrentHour() {
    return moment().format('HH') + ":00"
}

export function getCurrentShow(schedule, shows) {
    const currentDay = getCurrentDay();
    const todaySchedule = convertFilledScheduleToOneDaySlots(schedule, currentDay);
    if (schedule !== null) {
        const currentShow = shows.find(s => s.ShowID === schedule[getCurrentHour()][currentDay-1]?.ShowID);
        if(currentShow === undefined) return null;
        return {
            ...currentShow,
            slot: todaySchedule.find(s => s.id === schedule[getCurrentHour()][currentDay - 1].id)
        };
    }
}

export function getHostNames(show) {
    let finalString = show.HostNames.find(h => h.UserID === show.Host).Name;
    show.Hosts.forEach((cohost, index) => {
        const lastCoHost = show.Hosts.length - 1;
        if (index === lastCoHost) finalString += ` & ${show.HostNames.find(h => h.UserID === cohost).Name}`;
        else finalString += `, ${show.HostNames.find(h => h.UserID === cohost).Name}`;
    });
    return finalString;
}