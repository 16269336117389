const HOST = process.env.NODE_ENV === 'production' ? "https://api.nervemedia.org.uk/v2/" : 'http://localhost:4000/v2/';
const HOST_v0 = "https://api.nervemedia.org.uk/v0/"

export async function getFullSchedule() {
    const res = await(fetch(HOST + 'schedule').catch(console.warn));
    if (res.ok) {
        const body = await res.json();
        if (body.success === true) return body.schedule;
        throw new Error('UnableToRetrieveSchedule');
    }
    throw new Error('UnableToRetrieveSchedule')
}

export async function getAllShows() {
    const res = await(fetch(HOST + 'shows').catch(console.warn));
    if (res.ok) {
        const body = await res.json();
        if (body.success === true) return body.shows;
        throw new Error('UnableToRetrieveShows');
    }
    throw new Error('UnableToRetrieveShows')
}

// TODO: Migrate Song history to new API
export async function getSongHistory(){
    const res = await(fetch(HOST_v0 + 'played_history?limit=10').catch(console.warn));
    if (res.ok) {
        const body = await res.json();
        return body;
    }
    throw new Error('UnableToRetrieveSongHistory')
}

export async function getShowRecordings(showId){
    const res = await(fetch(HOST + 'shows/' + showId + '/episodes').catch(console.warn));
    if (res.ok) {
        const body = await res.json();
        if (body.success === true) return body.recordings;
        throw new Error('UnableToRetrieveShowRecordings');
    }
    throw new Error('UnableToRetrieveShowRecordings')
}