import React, { useEffect, useRef, useState } from "react";
import './radioShowModal.css';
import { getShowRecordings } from '../utils/nerveApi';
import { getHostNames } from "../utils/scheduleHelper";

const RadioShowModal = ({ show, visible, setVisible, resetShow }) => {

    const [episodes, setEpisodes] = useState([]);

    useEffect(() => {
        setEpisodes([]);
        if (show?.ShowID !== undefined) {
            (async () => {
                const recordings = await getShowRecordings(show.ShowID);
                setEpisodes(recordings);
            })();
        }
    }, [show]);

    return (
        <>
            <div className={"modal zoomInEffect" + (visible ? " show" : "")}>
                <div className="modal-content">
                    <h1 className="showPageTitle">{show?.Name || ''}</h1>
                    <small className="showPageHosts">By {show && getHostNames(show)}</small>
                    <div className="showPageContent">
                        <div>
                            <img src={show?.Image || "https://static.nervemedia.org.uk/show_images/logotest.png"} style={!show?.Image && { filter: 'grayscale(1)  brightness(1.5)' }} loading="lazy" className="showPageImage" />
                        </div>
                        <div className="showPageDetails">
                            <b>About the show:</b>
                            <p>{show?.Description || "This show doesn't have a description yet, but hang tight, we're working on it!"}</p>

                            {show?.Instagram &&
                                <>
                                    <b>Socials</b>
                                    <p className="InstagramInfo" style={{ color: 'white' }}>
                                        <i className="icon-instagram"></i>
                                        <a href={"https://instagram.com/" + show?.Instagram} style={{ color: 'lightgray' }} target="_blank">@{show?.Instagram}</a>
                                    </p>
                                </>
                            }
                        </div>
                    </div>
                    <div style={{ overflow: 'visible' }}>
                        {episodes.length !== 0 &&
                            <>
                                <b>Previous Episodes</b>
                                {episodes.map((episode, index) => {
                                    return (
                                        <Episode key={index} index={episodes.length - index} episode={episode} show={show} />
                                    );
                                })}
                            </>
                        }
                    </div>
                    <div className="spacer" />
                </div>
            </div>
            <div className="overlay" onClick={() => { setVisible(false); resetShow(); }} />
        </>
    );
};

const currentEpisodes = {};

const updateMediaSession = (episodeIndex, show) => {
    if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
            title: `${show.Name} - Episode #${episodeIndex}`,
            artist: 'Nerve Radio',
            artwork: [
                { src: 'https://player.nervemedia.org.uk/favicon.ico', sizes: '96x96', type: 'image/png' },
                { src: 'https://player.nervemedia.org.uk/favicon.ico', sizes: '512x512', type: 'image/png' },
            ]
        });
    }
}

const Episode = ({ index, episode, show }) => {

    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [manualChange, setManualChange] = useState(false);

    currentEpisodes[episode.RecordingID] = useRef();

    const handleEpisodePlay = () => {
        if (!playing) {
            Object.values(currentEpisodes).forEach((ep) => {
                ep?.current?.pause();
            });
            currentEpisodes[episode.RecordingID].current.play();
            setPlaying(true);
            updateMediaSession(index, show);
        } else {
            currentEpisodes[episode.RecordingID].current.pause();
            setPlaying(false);
        }
    }

    const handleSeekChange = (e) => {
        currentEpisodes[episode.RecordingID].current.currentTime = e.target.value;
        setManualChange(false);
    }

    const handleTimeUpdate = (e) => {
        if (!manualChange) setCurrentTime(Math.round(e.target.currentTime));
    }

    return (
        <div className={"showEpisode" + (playing ? " active" : "")}>
            <audio
                src={`https://archive.nervemedia.org.uk/shows/${episode.FileName}`}
                ref={currentEpisodes[episode.RecordingID]}
                onPause={() => setPlaying(false)}
                onEnded={() => setPlaying(false)}
                onTimeUpdate={handleTimeUpdate}
            ></audio>
            <img src={playing ? '/assets/pause.png' : '/assets/play.png'} onClick={handleEpisodePlay} />
            <b>Episode #{index}</b>
            <input
                type="range"
                style={{ flexGrow: 1 }}
                className='player-seeker'
                step={1}
                value={currentTime}
                max={episode.Duration}
                onMouseUp={handleSeekChange}
                onTouchEnd={handleSeekChange}
                onChange={(e) => {
                    setManualChange(true);
                    setCurrentTime(e.target.value)
                }}
            />
            <span>
                {Math.floor(currentTime / 60).toString().padStart(2, '0')}:{Math.floor(currentTime % 60).toString().padStart(2, '0')}
                /
                {Math.floor(episode.Duration / 60).toString().padStart(2, '0')}:{Math.floor(episode.Duration % 60).toString().padStart(2, '0')}
            </span>
        </div>
    );
}

export default RadioShowModal;