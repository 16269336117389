import React, { useEffect, useState } from 'react';
import { convertFilledScheduleToOneDaySlots, getCurrentDay, getDayString } from '../utils/scheduleHelper';
import './schedule.css'

export default function Schedule({ isMobile, schedule, shows, openModal }) {
    const Wrapper = isMobile ? MobileView : DesktopView;
    const [dayView, setDayView] = useState(getCurrentDay());
    return (
        <Wrapper changeDayView={setDayView}>
            {isMobile
                ?
                <ScheduleDay day={dayView} schedule={schedule} shows={shows} openModal={openModal} />
                :
                <ScheduleWeek schedule={schedule} shows={shows} openModal={openModal} />
            }
        </Wrapper>
    )
}

function MobileView({ children, changeDayView }) {
    return (
        <div >
            <select
                defaultValue={getCurrentDay()}
                onChange={(event) => {
                    changeDayView(parseInt(event.target.value))
                }}
                style={{ all: 'unset', border: '1px solid lightgray', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', borderTop: '1px solid white', padding: '5px', paddingBottom: '10px', color: 'rgb(224 16 100)', background: 'white', position: 'absolute', left: 0, right: 0, width: 'fit-content', margin: 'auto', textAlign: 'center' }}
            >
                <option value="2">Monday</option>
                <option value="3">Tuesday</option>
                <option value="4">Wednesday</option>
                <option value="5">Thursday</option>
                <option value="6">Friday</option>
                <option value="7">Saturday</option>
                <option value="8">Sunday</option>
            </select>
            <div style={{ paddingTop: '30px', paddingBottom: '50px' }}>
                {children}
            </div>
        </div>
    )
}

function DesktopView({ children }) {
    return (
        <>
            {children}
        </>
    )
}

function ScheduleDay({ day, schedule, shows, openModal }) {

    const [dailySlots, setDailySlots] = useState(null)

    useEffect(() => {
        if (typeof schedule === 'object' && typeof shows === 'object') setDailySlots(convertFilledScheduleToOneDaySlots(schedule, day))
    }, [schedule, day])

    return (
        <div style={{ flexGrow: 1 }}>
            {dailySlots !== null && dailySlots.map((slot, index) => {
                const show = shows.find(s => s.ShowID === slot.ShowID);
                return (
                    <div
                        key={index}
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            borderBottom: '1px solid white',
                            padding: '15px 0',
                            margin: "0"
                        }}
                        onClick={() => openModal(show)}
                    >
                        <span style={{ lineHeight: '14px', fontSize: '20px' }}>{slot.timeStart}<br />-<br />{slot.timeEnd}</span>
                        <div style={{ flexGrow: 1 }}>
                            <span>{show.Name}</span><br />
                            <span style={{ fontSize: '14px', color: 'lightgray' }}>{show.Description.substr(0, 35)}{show.Description.length > 35 && '...'}</span>
                        </div>
                        <img style={{ height: '15px', transform: 'rotateZ(90deg)' }} src='/assets/arrow-up.png' alt={"Go to show " + show.name} />
                    </div>
                )
            })}
        </div>
    )
}

function ScheduleWeek({ schedule, shows, openModal }) {
    if(schedule === null || shows === null) return (<div></div>)
    var spanningDay = { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false }
    return (
        // <div style={{ display: 'flex', justifyContent: 'center' }}>
        <table id='schedule' style={{width: '100%', height: '100%', borderCollapse: 'collapse'}}>
            <thead>
                <tr>
                    <th></th>
                    <th>{getDayString(2)}</th>
                    <th>{getDayString(3)}</th>
                    <th>{getDayString(4)}</th>
                    <th>{getDayString(5)}</th>
                    <th>{getDayString(6)}</th>
                    <th>{getDayString(7)}</th>
                    <th>{getDayString(8)}</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(schedule).map(hour => {
                    const daySlots = schedule[hour];
                    return (
                        <tr key={hour}>
                            <th>{hour}</th>
                            {Object.keys(daySlots).map(day => {
                                const currentShow = daySlots[day];
                                const show = shows.find(s => s.ShowID === currentShow?.ShowID)
                                if (spanningDay[day] && currentShow.duration === 1) {
                                    spanningDay[day] = false;
                                    return null;
                                } else if (spanningDay[day]) return null;
                                if (currentShow !== null) {
                                    if (currentShow.duration > 1) {
                                        spanningDay[day] = true;
                                    }
                                }

                                if(currentShow === null){
                                    return (<td key={day} style={{color: 'lightgrey'}}><i><small>No show</small></i></td>)
                                }
                                return (
                                    <td
                                        key={day}
                                        rowSpan={currentShow?.duration}
                                        style={{border: '1px solid white'}}
                                        onClick={() => openModal(show)}
                                    >
                                        {show?.Name}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
        // </div>
    )
}