import React, { useEffect, useState } from "react";
import './App.css'
import Loader from "./components/loader";
import RadioShowModal from "./components/radioShowModal";
import WavyBottom from "./components/wavyBottom";
import FooterMain from "./footerMain";
import MainPage from "./mainPage";
import { getAllShows, getFullSchedule } from "./utils/nerveApi";
import { convertSlotsToFilledSchedule, getCurrentShow, getHostNames } from "./utils/scheduleHelper";

const src = 'https://stream.nervemedia.org.uk/nerve-radio';
let audio;
let loading = true;
let error = null;

export default function App() {
    const [isLoading, setLoading] = useState(true);
    const [displayLoader, setDisplayLoader] = useState(true);
    const [sectionExpanded, setSectionExpanded] = useState(false);

    const [streamLoading, setStreamLoading] = useState(false);
    const [streamPlaying, setStreamPlaying] = useState(false);
    const [currentSongPercentCompleted, setCurrentSongPercentCompleted] = useState(0);
    const [currentShow, setCurrentShow] = useState(null);

    const [schedule, setSchedule] = useState(null);
    const [shows, setShows] = useState(null);

    const [errorText, setErrorText] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedShow, setSelectedShow] = useState(null);

    useEffect(() => {

        (async () => {
            try {
                console.log('Loading audio file')
                audio = new Audio();

                audio.onplaying = function () {
                    if (!error) {
                        console.log('Audio file playing.')
                        setStreamPlaying(true);
                        setStreamLoading(false);
                    }
                }

                setTimeout(() => {
                    if (loading) {
                        error = "TimeoutException: Unable to load the required data. Please try again later";
                        setErrorText("TimeoutException: Unable to load the required data. Please try again later")
                    }
                }, 30000)

                audio.src = src
                await audio.play().catch(console.warn);

                const allShows = await getAllShows().catch(console.warn);
                const fullSchedule = await getFullSchedule().catch(console.warn);
                const filledSchedule = convertSlotsToFilledSchedule(fullSchedule, allShows);
                setShows(allShows)
                setSchedule(filledSchedule)

                setLoading(false);
                loading = false;
                setDisplayLoader(false);
            } catch (e) {
                console.log(e)
            }
        })()

        // setInterval(() => {
        //     setCurrentSongPercentCompleted(prev => prev + 0.1)
        // }, 500)

    }, []);

    const startStream = () => {
        setStreamLoading(true);
        audio.play();

        if ('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: `LIVE | ${currentShow?.Name || 'Nerve Radio'}`,
                artist: 'Nerve Radio',
                artwork: [
                    { src: 'https://player.nervemedia.org.uk/favicon.ico', sizes: '96x96', type: 'image/png' },
                    { src: 'https://player.nervemedia.org.uk/favicon.ico', sizes: '512x512', type: 'image/png' },
                ]
            });
        }

    }

    const endStream = () => {
        setStreamLoading(false);
        setStreamPlaying(false);
        audio.pause();
    }

    useEffect(() => {
        setCurrentShow(getCurrentShow(schedule, shows))
    }, [schedule, shows]);

    const handleModalOpen = (show) => {
        setModalOpen(true);
        setSelectedShow(show);
    }

    const resetShow = () => {
        setTimeout(() => {
            setSelectedShow(null);
        }, 500);
    };

    //TODO: Finish implementing current song bar - percent of song complete.
    return (
        <>
            {displayLoader &&
                <div id="loader" className={!isLoading ? "hide" : ""}>
                    <Loader />
                </div>
            }
            <RadioShowModal show={selectedShow} visible={modalOpen} setVisible={setModalOpen} resetShow={resetShow} />
            <MainPage hide={isLoading} fade={sectionExpanded} schedule={schedule} shows={shows} />
            <WavyBottom fullscreen={isLoading} expanded={sectionExpanded} modalOpen={modalOpen}>
                <footer>
                    <div className="playerData">
                        <div style={{ width: "20px", flexGrow: 0 }} />
                        <img
                            src={streamLoading ? "/assets/loading-stream.gif" : !streamPlaying ? "/assets/play.png" : "/assets/pause.png"}
                            className="streamActionBtn"
                            alt="Play Button"
                            onClick={!streamLoading ? !streamPlaying ? startStream : endStream : null}
                        />
                        <div
                            style={{
                                textAlign: "center"
                            }}
                        >
                            <span className="showTitle">{currentShow?.Name}</span>
                            
                            {currentShow !== null && currentShow !== undefined && <><br /><span className="showDesc">By {getHostNames(currentShow)}</span></>}
                        </div>
                        <img
                            src="/assets/arrow-up.png"
                            className={"sectionExpansionBtn" + (sectionExpanded ? " expand" : "")}
                            alt="Exapnd/Collapse section"
                            onClick={() => { setSectionExpanded(s => !s) }}
                        />
                        <div style={{ width: "20px", flexGrow: 0 }} />
                    </div>
                    <div>
                        <div className="currentSongBarContainer">
                            <div className="currentSongBar" style={{ width: '100%'/* "calc(100vw / 100 * " + currentSongPercentCompleted + ")" */ }} />
                        </div>
                    </div>
                    {/* TODO: Finish Current song length bar */}
                    <div id="footerSections" className={isLoading ? "hide" : ""}>
                        <FooterMain schedule={schedule} shows={shows} openModal={handleModalOpen} />
                    </div>
                </footer>
            </WavyBottom>
            <div id="error" className={errorText === null ? "" : "show"}>
                <span>{errorText}</span>
                <br />
                <a href="/">Retry</a>
            </div>
        </>
    )
}